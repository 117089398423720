/**
 * hide here all validation logic for phone number
 */

import { PhoneNumberUtil } from 'google-libphonenumber';
import Utils from 'legacy/utils';

export const isNumber = (value) => {
  let reg = /^(0?7)\d{9}$/;

  if (Utils.region() === 'IE') {
    // reg = /^(07)\d{8}$/;
    reg = /^0?\d{9}$/;
  }

  return value.match(reg) !== null;
};

// singleton instance for phone util
let phoneUtilInstance = null;

const getInstance = () => {
  if (!phoneUtilInstance) {
    phoneUtilInstance = PhoneNumberUtil.getInstance();
  }
  return phoneUtilInstance;
};

/**
 *
 * Parse string and return a numeric phone number
 *
 * @param {*} value
 */
export const parse = (value) => {
  const region = Utils.region();
  return getInstance().parse(value, region);
};

/**
 *
 * Returns true iff `value` is a valid numeric phone number
 *
 * @param {*} value
 */
export const isValid = (value, strictNumber = false) => {
  try {
    if (!isNumber(value) && strictNumber) {
      return false;
    }
    const parsed = parse(value);
    return getInstance().isValidNumber(parsed);
  } catch (err) {
    return false;
  }
};

export default { isValid, parse };
