/**
 *
 * it returns a random number witha given length.
 *
 * @param {*} length, length of the number
 */
const randomNumbers = (length) => {
  const base = 10 ** (length - 1);

  return Math.floor(base + (Math.random() * 9 * base));
};

export default randomNumbers;
