/* eslint-disable import/prefer-default-export */

/**
 * Logic shared with legacy.
 *
 * Legacy vendors must be imported in every layout file
 * BEFORE `<%= javascript_include_tag 'application' %>`.
 *
 * This pack is a necessary intermediate step before moving
 * all the logic to webpacker.
 *
 * Please refer to variable in the global scope also in Vue pack
 * because, otherwise, you import some dependencies twice.
 * E.g. in `packs/application.js` and `packs/shared_with_legacy.js`.
 *
 * This pack can be removed when all legacy component are migrated.
 *
 */

// Some 3rd party tracking scripts require jquery
import $ from 'jquery';

import phone from '../random/phone';
import number from '../random/number';
import email from '../random/email';
import DmpgDataLayer from '../utils/dmpg_data_layer';

window.$ = $;
window.jQuery = $;

const dataLayer = new DmpgDataLayer(window);

// exported modules will be available in global scope
// i.e. Packs.shared_with_legacy.PhoneUtils
export const Random = { number, phone, email };
export { default as PhoneUtils } from '../utils/phone';
export { default as HiveLoader } from '../utils/hive_loader';
export { default as Utils } from '../legacy/utils';
export { dataLayer };
