import castArray from 'utils/array';
import kebabCase from 'lodash/kebabCase';

class DmpgDataLayer {
  constructor(scope = window) {
    this.scope = scope;
  }

  get hdl() {
    if (this.scope.hdl === undefined) this.scope.hdl = {};

    return this.scope.hdl;
  }

  get event() {
    if (this.hdl.event === undefined) this.hdl.event = [];

    return this.hdl.event;
  }

  // Another artifact of the strange datalayer requirements.
  // Sometimes window.hdl.products is a single product object,
  // sometimes it is an array of product objects, and other times
  // it will be an array of SKUs.
  // https://jira.bgchtest.info/browse/HWS-2958
  get productsArray() {
    return castArray(this.hdl.products || {});
  }

  get error() {
    if (this.hdl.error === undefined) this.hdl.error = {};

    return this.hdl.error;
  }

  get assets() {
    if (this.hdl.assets === undefined) this.hdl.assets = {};

    return this.hdl.assets;
  }

  get promotions() {
    if (this.assets.promotions === undefined) this.assets.promotions = [];

    return this.assets.promotions;
  }

  get user() {
    if (this.hdl.user === undefined) this.hdl.user = { device: {} };

    return this.hdl.user;
  }

  get device() {
    return this.user.device;
  }

  set customer(customerInfo) {
    this.user.customer = customerInfo;
  }

  appendEvent(event) {
    this.event.push(event);
  }

  productCartChange(qtyBefore, qtyAfter, productSku = null) {
    let event;

    if (qtyBefore === 0) {
      event = 'product.interact.manual.click.addToCart';
    } else if (qtyAfter === 0) {
      event = 'product.interact.manual.click.removeFromCart';
    } else if (qtyBefore < qtyAfter) {
      event = 'product.interact.manual.click.unitChangePlus';
    } else {
      event = 'product.interact.manual.click.unitChangeMinus';
    }

    const productId = productSku || this.productsArray[0] || null;

    if (event.match(/unitChange|addToCart/)) {
      this.appendEvent({
        event,
        cartLine: {
          id: productId,
          quantity: qtyAfter,
        },
      });
    } else {
      this.appendEvent({
        event,
        product: { id: productId },
      });
    }
  }

  manualClick({ moduleId, moduleItemId }) {
    this.appendEvent({
      event: 'moduleItem.interact.manual.click',
      moduleId,
      moduleItemId,
    });
  }

  customManualInteraction(interaction, params) {
    this.appendEvent({
      event: `moduleItem.interact.manual.${interaction}`,
      ...params,
    });
  }

  productLoaded(opts = {}) {
    const { variants } = opts;
    const firstProduct = this.productsArray[0] || {};
    const sku = firstProduct.id || null;
    const product = this.productsArray.find(({ id }) => sku === id);

    if (!product) {
      return;
    }

    if (variants) {
      const { variants: existingVariants } = product;
      product.variants = { ...existingVariants, ...variants };
    }

    this.appendEvent({
      event: 'product.load.auto.dataLayer.load',
      product,
    });
  }

  productClickFromList() {
    const comesFromProductsPageReferrer = (document.referrer.search('/products') > 0
      && document.referrer.search('/products/') === -1);
    const comesFromCategory = (document.referrer.search('/products/categories') > 0);

    if (comesFromProductsPageReferrer || comesFromCategory) {
      this.manualClick({ moduleId: 'product-list', moduleItemId: this.hdl.products.id });
    }
  }

  marketingPreferences(permissions) {
    this.appendEvent({
      event: 'form.interact.manual.submit.marketingPermissionSubmit',
      form: {
        id: 'marketing-permissions',
        permissions,
      },
    });
  }

  emailSubscribe() {
    this.appendEvent({ event: 'user.interact.manual.click.emailSignUp' });
  }

  productAddToCart(args) {
    const { sku, quantity } = args || {};

    this.productCartChange(0, quantity || 1, sku); // this ensures an addToCart event
  }

  productRemoveFromCart(args) {
    const { sku } = args || {};

    this.productCartChange(1, 0, sku); // this ensures a removeFromCart event
  }

  productQtyChange({ sku, quantity, oldQuantity }) {
    this.productCartChange(parseInt(oldQuantity, 10), parseInt(quantity, 10), sku);
  }

  triggerCartEvent(event) {
    if (event == null) { return; }

    const { cart = {} } = this.hdl;
    const { id = null } = cart;

    this.appendEvent({
      event,
      cart: { id },
    });
  }

  transactionFailure(transaction) {
    this.appendEvent({
      event: 'transaction.load.auto.failure.transactionFailure',
      transaction,
    });
  }

  programme(args) {
    const { action, id, duration } = args || {};

    switch (action) {
      case 'load':
        return this.appendEvent({
          event: 'programme.load.auto.dataLayer.videoLoad',
          programme: { id, duration },
        });
      case 'autoPlay':
        return this.appendEvent({
          event: 'programme.load.auto.avPlayer.videoPlay',
          programme: { id },
        });
      case 'manualPlay':
        return this.appendEvent({
          event: 'programme.interact.manual.click.videoPlay',
          programme: { id },
        });
      case 'pause':
        return this.appendEvent({
          event: 'programme.interact.manual.click.pause',
          programme: { id },
        });
      case 'end':
        return this.appendEvent({
          event: 'programme.load.auto.avPlayer.end',
          programme: { id },
        });
      case 'close':
        return this.appendEvent({
          event: 'programme.interact.manual.click.videoClose',
          programme: { id },
        });
      default:
        return false;
    }
  }

  updateError(httpResponseCode, type, serverMessage, newUserMessage) {
    const { error } = this;
    const userMessage = error.userMessage ? `${error.userMessage}|${newUserMessage}` : newUserMessage;

    this.hdl.error = {
      ...error,
      httpResponseCode,
      type,
      serverMessage,
      userMessage,
    };
  }

  replace(newHdl) {
    const {
      event: newEvents = [],
      products,
      error = {},
      assets: newAssets = {},
      user = {},
      ...otherProps
    } = newHdl;

    const { promotions: newPromotions = [] } = newAssets;
    const { device = {} } = user;
    const promotions = castArray(this.promotions, newPromotions);

    this.event.push(...castArray(newEvents));

    Object.assign(this.hdl, {
      ...otherProps,
      // We are not normalizing `products` here, as it can either be an object or array,
      // depending on the current page: https://jira.bgchtest.info/browse/HWS-2958
      products,
      error,
      user: { ...user, device },
      assets: { ...newAssets, promotions },
    });
  }

  addPromotion(promotionId) {
    const { promotions } = this;

    if (promotionId && promotions.findIndex(el => el.id === promotionId) === -1) {
      promotions.push({ id: promotionId });
    }
  }

  updateViewSize() {
    const { screen: { width, height }, innerWidth, innerHeight } = this.scope;

    this.device.screenSize = `${width}x${height}`;
    this.device.viewPort = `${innerWidth}x${innerHeight}`;
  }

  appendUpsellPageLoadedEvent(recommendationsForSku) {
    const upsellModule = this.hdl.modules.find(({ id }) => id === 'OSP') || {};
    const items = upsellModule.items || [];

    this.appendEvent({
      event: 'dataLayer.load.auto.dataLayer.load',
      modules: [{
        id: 'OSP',
        type: 'upsell',
        name: `OSP-${recommendationsForSku}`,
        items,
      }],
    });
  }

  appendUpsellItemSelectedEvent(recommendationsForSku, selectedSku) {
    this.appendEvent({
      event: 'moduleItem.interact.manual.click',
      moduleId: 'OSP',
      moduleName: `OSP-${recommendationsForSku}`,
      moduleItemId: selectedSku,
    });
  }

  chooseTopic(subject, topic) {
    this.appendEvent({
      event: 'formField.interact.manual.click.contactUs',
      formField: {
        subject: kebabCase(subject),
        topic: kebabCase(topic),
      },
    });
  }

  addHeatingOfflineEvent(event, data) {
    const payload = {
      event,
    };

    if (data) {
      payload.data = data;
    }

    this.appendEvent(payload);
  }
}

export default DmpgDataLayer;
