/**
 * Wrapper around global hive loader actions.
 *
 * It allows legacy code to control the global HiveLoader programmatically.
 *
 * If you need a new `hive-loader`, please consider to use the local version, where
 * the loader is defined in a declarative way.
 * You can check [here](/app/javascript/base/slot_button.js) for an example of usage.
 *
 */
const show = () => {
  // access the exported global store since this code will be called from legacy code
  const store = window && window.store;
  if (store) {
    return store.dispatch('global/showHiveLoader');
  }
  // this should not happen
  throw new Error('Cannot show HiveLoader: Vue Store has not been initialized yet.');
};

const hide = () => {
  // access the exported global store since this code will be called from legacy code
  const store = window && window.store;
  if (store) {
    return store.dispatch('global/hideHiveLoader');
  }
  // this should not happen
  throw new Error('Cannot hide HiveLoader: Vue Store has not been initialized yet.');
};

export default { show, hide };
