// Usage:
//  Option 1: Add data-scroll-to with id_of_element as value,
//  optionally add data-offset and data-speed to customize.
//  Option 2: Use directly. See example: javascripts/hive/utils.js.coffee#scrollToAnchor
//       smoothScroll = new SmoothScroll(id_of_element, id_of_element)
//       smoothScroll.perform() # Need to repeat id_of_element here!

class SmoothScroll {
  constructor(eventTarget, destination) {
    this.eventTarget = $(eventTarget);
    this.destination = destination;

    this.defaultSpeed = 1100;
    this.defaultOffset = $('.global-navbar').height();
  }

  top() {
    if (this.destination === 'top') { return 0; }

    let { destination } = this;
    if (destination == null) {
      if (this.eventTarget.length === 0) {
        throw new Error('Either eventTarget or valid destination are required');
      }
      destination = this.eventTarget.data('scroll-to') || this.eventTarget.attr('href');
    }
    destination = $(destination);

    if (destination.length === 0) { return false; }

    return destination.offset().top;
  }

  speed() {
    return this.eventTarget.data('speed') || this.defaultSpeed;
  }

  offset() {
    return this.eventTarget.data('offset') || this.defaultOffset;
  }

  perform() {
    let top = this.top();

    if (top === false) { return false; }

    top -= this.offset();
    $('html, body').stop().animate({ scrollTop: top }, this.speed());

    return true;
  }
}

export default SmoothScroll;
