import SmoothScroll from 'legacy/smooth_scroll';

const Utils = {
  addUrlParam(url, name, value) {
    let myUrl = url;
    const add = (sep) => {
      myUrl += `${sep}${name}=${encodeURIComponent(value)}`;
    };

    const re = new RegExp(`([?&]${name}=)[^&]+`, '');
    const change = () => {
      myUrl = myUrl.replace(re, `$1${encodeURIComponent(value)}`);
    };

    if (myUrl.indexOf('?') === -1) {
      add('?');
    } else if (re.test(myUrl)) {
      change();
    } else {
      add('&');
    }

    return myUrl;
  },

  getUrlParamVal(name, url) {
    // eslint-disable-next-line no-useless-escape
    const sanitizedName = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regexS = `[\\?&]${sanitizedName}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(url);
    if (results === null) {
      return null;
    }

    return results[1];
  },

  changeParam(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    }

    return `${uri}${separator}${key}=${value}`;
  },

  scrollToAnchor(anchorId) {
    const tag = `#${anchorId}`;
    if (tag.length > 1) {
      const smoothScroll = new SmoothScroll(tag, tag);
      return smoothScroll.perform();
    }
    return null;
  },

  formatCurrency(number) {
    return `${window.Hive.currency.symbol}${number}`;
  },

  region() {
    return (window.Hive.region || 'GB').toUpperCase();
  },
};

export default Utils;
