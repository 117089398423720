import randomNumbers from './number';

const randomPhone = (marketScope) => {
  switch (marketScope) {
    case 'ie':
      return `07${randomNumbers(8)}`;
    case 'it':
      return `039${randomNumbers(7)}`;
    case 'fr':
      return `0590${randomNumbers(6)}`;
    case 'us':
      return `120${randomNumbers(8)}`;
    case 'ca':
      return `4165${randomNumbers(6)}`;
    default:
      return `07${randomNumbers(9)}`;
  }
};

export default randomPhone;
